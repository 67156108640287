import React, { useRef } from 'react';

// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AnimatePresence, m } from 'framer-motion';
import { Parallax } from "react-scroll-parallax";
import { Form, Formik } from 'formik';
import { Link as ScrollTo } from "react-scroll"


import { ContactFormStyle03Schema } from '../../Components/Form/FormSchema';
import { Checkbox, TextArea } from '../../Components/Form/Form'


// Components
import Header, { HeaderNav, Menu } from "../../Components/Header/Header";
import { fadeIn } from "../../Functions/GlobalAnimations";
import Buttons from '../../Components/Button/Buttons'
import FancyTextBox from '../../Components/FancyTextBox/FancyTextBox'
import BlogClassic from "../../Components/Blogs/BlogClassic";
import Clients from '../../Components/Clients/Clients'
import CustomModal from '../../Components/CustomModal'
import Lists from "../../Components/Lists/Lists";
import Services from "../../Components/Services/Services";
import MessageBox from "../../Components/MessageBox/MessageBox";
import TestimonialsCarousel04 from "../../Components/TestimonialCarousel/TestimonialsCarousel04";
import { Input } from '../../Components/Form/Form'
import { resetForm, sendEmail } from "../../Functions/Utilities";
import { ContactFormStyle02Schema } from "../../Components/Form/FormSchema";
import FooterStyle04 from "../../Components/Footers/FooterStyle04";
import SideButtons from "../../Components/SideButtons";


// Data
import { fancyTextBox01 } from "../../Components/FancyTextBox/FancyTextBoxData";
import { serviceData4 } from "../../Components/Services/ServicesData";
import { ClientData02 } from "../../Components/Clients/ClientsData";
import { ClientData01 } from "../../Components/Clients/ClientsData";
import { TestimonialsCarouselData4 } from "../../Components/TestimonialCarousel/TestimonialsCarouselData";
import { blogData } from "../../Components/Blogs/BlogData";
import InViewPort from "../../Components/InViewPort";



const serviceData = [
  {
    img: "../assets/img/webp/fleetweb.webp",
    icon: "fas fa-arrow-right",
    title: "WAGENPARK",
    content:
      "Elke koeriersopdracht heeft een meest ideale vervoersmiddel. Ons wagenpark voldoet in 99% ...",
    link: "/page/our-story",
  },
  // {
  //   img: "../assets/img/webp/testimonials.webp",
  //   icon: "fas fa-arrow-right",
  //   title: "REFERENTIES",
  //   content:
  //     "Lees wat anderen over ons zeggen. <p>  fsdfdsdsffsdafdsdsafdsa <P> Wij zijn trots op onze klanten en zij op ons...",
  //   link: "/page/our-story",
  // },
];

const listData = [
  {
    icon: "fas fa-check",
    title: "Healthy profits for long term",
    content:
      "Lorem ipsum consectetur adipiscing eiusmod tempor incididunt labore.",
  },
  {
    icon: "fas fa-check",
    title: "Working towards your business",
    content:
      "Lorem ipsum consectetur adipiscing eiusmod tempor incididunt labore.",
  },
];

// Filter the blog data category wise
const blogClassicData = blogData.filter((item) => item.blogType === "classic").filter(item => item.category.includes("finance"));

const NnrHome = (props) => {
  const form = useRef(null)
  return (
    <div className="financepage" style={props.style}>
      <SideButtons />
      {/* Header Start */}
      <Header 
        topSpace={{ md: true }} 
        type="always-fixed"
        // type="reverse-scroll" 
        theme="light" 
        bg="light" 
        menu="light"
        className="border-b border-[rgba(255,255,255,.1)]">

        <HeaderNav
          fluid="fluid"
          theme="light"
          expand="lg"
          containerClass="sm:px-0"
          className="py-[0px] px-[35px] md:py-[18px] md:px-0"
        >
          <Col sm={6} lg={2} xs="auto" className="me-auto ps-0">
            <Link aria-label="logo home" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img className="default-logo" width="100" height="36" loading="lazy" src='/assets/img/webp/nnr.png' data-rjs='/assets/img/webp/nnr.png' alt='logo' />
                <img className="alt-logo" width="100" height="36" loading="lazy" src='/assets/img/webp/nnr.png' data-rjs='/assets/img/webp/nnr.png' alt='logo' />
                <img className="mobile-logo" width="100" height="36" loading="lazy" src='/assets/img/webp/nnr.png' data-rjs='/assets/img/webp/nnr.png' alt='logo' />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle className="order-last md:ml-[17px] md:px-[15px]" >
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse className="col-auto justify-center menu-order finance-header-menu px-lg-0 col-lg-8">
            <Menu {...props} />
          </Navbar.Collapse>
          <Col xs="auto" lg={2} className="text-right pe-0">
            <Buttons
              to="#"
              className="rounded-[4px] font-medium font-serif uppercase shadow-[0_0_30px_rgba(0,0,0,0.08)] sm:hidden"
              themeColor={["#fff", "#fff"]}
              size="xs"
              color="#2d95ef"
              title="Login"
            />
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}

      {/* Parallax Section Start */}
      <div className="overflow-hidden relative">
        <Parallax
          className="lg-no-parallax sm:hidden bg-cover absolute top-[0px] left-0 w-full h-[100vh] md:h-[90vh] sm:h-[500px]"
          translateY={[-40, 40]}
          style={{
            backgroundImage: `url("../assets/img/webp/nnrhero.svg")`,
          }}
        ></Parallax>
        <Container id="home" className="relative">
          <Row className="items-center h-[100vh] md:h-[650px] sm:h-[450px]">
            <Col lg={5} md={6} sm={8}>
              <span className="text-xmd font-serif font-medium uppercase text-[#2D95EF] block mb-[35px] xs:mb-[15px]">
                {/* Logistieke dienstverlening */}
              </span>
              <h1 className="font-serif font-bold text-[#2f2f2f] uppercase mb-[2.5rem] tracking-[-2px] sm:mb-[20px] xs:mb-[15px]">
                Uw Logistieke partner
              </h1>
              <p className="text-xmd leading-[30px] w-[73%] mb-12 lg:w-[90%] md:w-full xs:mb-[15px]">
                Uw logistieke partner voor al uw transporten, zowel nationaal als internationaal
              </p>
              <ScrollTo href="/" to="form" offset={0} delay={0} spy={true} smooth={true} duration={800} className="cursor-pointer">
                <Buttons
                  type="submit"
                  className="btn-fill tracking-[1px] font-medium font-serif rounded-none uppercase md:mb-[15px] btn-fancy"
                  themeColor="#2D95EF"
                  color="#ffffff"
                  size="lg"
                  title="Neem contact op"
                />
              </ScrollTo>
            </Col>
          </Row>
          <ScrollTo to="about" offset={0} delay={0} spy={true} smooth={true} duration={800} className="w-full absolute bottom-[50px] left-0 right-0 z-[1] flex justify-center items-center xs:hidden cursor-pointer">
            <i className="fas fa-arrow-down text-[#2f2f2f] bg-white shadow-[0_0_25px_rgba(0,0,0,0.08)] w-[45px] h-[45px] leading-[46px] rounded-full flex justify-center items-center"></i>
          </ScrollTo>
        </Container>
      </div>
      {/* Parallax Section End */}

      {/* Section Start */}
      {/* <m.section {...fadeIn}>
        <Container fluid className="px-0">
          <FancyTextBox
            grid="row-cols-1 row-cols-xl-6 row-cols-md-3 row-cols-sm-2 gx-0"
            theme="fancy-text-box-01"
            data={fancyTextBox01}
            themeColor="light"
            animation={fadeIn}
          />
        </Container>
      </m.section> */}
      {/* Section End */}
      {/* Lazy Load HTML */}
      <InViewPort>
        {/* Section Start */}
        <section id="about" className="py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
          <Container>
            <Row className="items-center justify-center">
              <m.div
                className="col col-xl-4 col-lg-5 col-md-10 md:mb-20"
                {...fadeIn}
              >
                <h2 className="heading-5 font-serif text-[#2f2f2f] font-bold uppercase tracking-[-1px]">
                  Specialisten in het ontzorgen van uw transport
                </h2>
                <blockquote className="border-l-[4px] text-darkgray font-medium border-[#2d95ef] text-xmd pl-[25px] pr-0 mt-[40px] mb-[30px] lg:w-[95%]"> We nemen uw zorgen uit handen, zodat u zich kunt focussen op uw core business.
                </blockquote>
                <p className="w-[90%] mb-[25px] md:w-full">
                  NNR is een logistieke dienstverlener die zich heeft gespecialiseerd in het ontzorgen van uw transport. Wij zijn een betrouwbare partner voor al uw transporten, zowel nationaal als internationaal.
                </p>
                <Buttons
                  to="/"
                  className="font-medium mt-[10px] btn-fancy font-serif tracking-[1px] uppercase btn-transparent rounded-none hover:text-white"
                  color="#232323"
                  size="sm"
                  themeColor="#232323"
                  title="Lees verder"
                />
              </m.div>
              <m.div
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="col-12 col-md-10 col-lg-7 offset-xl-1"
              >
                <img
                  height=""
                  width=""
                  alt="..."
                  src="../assets/img/webp/pakjes.svg"
                />
              </m.div>
            </Row>
          </Container>
        </section>
        {/* Section End */}
       
        {/* LOGO Section Start */}
        <Clients
          className=""
          data={ClientData02}
          animation={fadeIn}
          animationDelay={0.2}
          theme="client-logo-style-04"
          grid="row-cols-1 row-cols-md-2 row-cols-lg-4 gap-y-5 margin-10"
        />
        {/* LOGO Section Start*/}

        {/* <m.section
          {...{ ...fadeIn, transition: { delay: 0.3 } }}
          id="about"
          className="py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
        >
          <Container>
            <Row className="items-center md:justify-center">
              <Col lg={4} md={8} className="md:text-center md:mb-24">
                <span className="font-serif text-md text-[#2D95EF] uppercase font-medium mb-[25px] inline-block sm:mb-[10px]">
                  Over Ons
                </span>
                <h2 className="heading-5 font-serif text-[#2f2f2f] font-bold uppercase tracking-[-1px] w-[80%] mb-[20px] lg:w-[90%] md:w-full">
                  Al jaren goed op weg
                </h2>
                <p className="w-[80%] mb-[35px] lg:w-full">
                  Excellente dienstverlening op het gebied van logistiek en transport.
                  Met deze gedachte is NNR opgericht en inmiddels uitgegroeid tot een 
                  volwaardige logistieke dienstverlener.
                </p>
                <Buttons
                  to="/"
                  className="btn-fancy font-medium font-serif tracking-[1px] uppercase btn-transparent rounded-none hover:text-white"
                  color="#232323"
                  size="sm"
                  themeColor="#232323"
                  title="Lees verder..."
                />
              </Col>
              <Col lg={8} md={12}>
                <Services
                  grid="row-cols-1 row-cols-md-2 justify-center text-left gap-y-10 md:gap-y-[45px]"
                  theme="service-style-01"
                  data={serviceData}
                  animation={fadeIn}
                  animationDelay={0.5}
                />
              </Col>
            </Row>
          </Container>
        </m.section> */}
        
        {/* Section End */}

        {/* Diensten Section Start */}
        <m.section id="Diensten"
          className="bg-lightgray py-[130px] overflow-hidden lg:py-[90px] md:py-[75px] sm:py-[50px]"
          {...fadeIn}
        >
          <Container>
            <Row className="justify-center">
              <m.div 
                className="col col-md-7 text-center mb-20 sm:mb-12"
                {...fadeIn}
              >
                <span  className="font-serif text-md text-[#2d95ef] uppercase font-medium inline-block mb-[15px] sm:mb-[10px]">
                  Onze diensten
                </span>
                <h2 className="heading-5 font-serif text-[#2f2f2f] font-bold uppercase tracking-[-1px]">
                  Excellente service in:
                </h2>
              </m.div>
            </Row>
            <Services
              grid="row-cols-lg-3 row-cols-sm-2 row-cols-1 gap-y-24"
              theme="service-style-04 items-center"
              data={serviceData4}
              animation={fadeIn}
            />
          </Container>
        </m.section>
        {/* Diensten Section End */}

                   

        {/* Referensties Section Start */}
        {/* <section className="border-t border-mediumgray py-[130px] overflow-hidden bg-gradient-to-b from-[#fff] to-[#f7f7f7] lg:py-[90px] md:py-[75px] sm:py-[50px]">
          <Container>
            <m.div className="row justify-center xs:mb-12" {...fadeIn}>
              <Col md={7} className="col-12 text-center mb-16 sm:m-12 xs:m-0">
                <span className="font-serif text-md text-[#8bb867] uppercase font-medium block mb-[15px] sm:mb-[10px]">
                  Satisfied clients
                </span>
                <h2 className="heading-5 font-serif text-[#2f2f2f] font-bold uppercase tracking-[-1px]">
                  Clients testimonials
                </h2>
              </Col>
            </m.div>
            <TestimonialsCarousel04
              data={TestimonialsCarouselData4}
              className="black-move"
              carouselOption={{
                slidesPerView: 1,
                loop: true,
                breakpoints: { 768: { slidesPerView: 2 } },
                navigation: false,
                autoplay: { delay: 3000, disableOnInteraction: false },
              }}
            />
          </Container>
        </section> */}
        {/* Referenties Section End */}

        {/* Section Start */}
      <m.section id="form" className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px]" {...fadeIn}>
        <Container>
          <Row className="justify-center">
            <Col xl={6} lg={7} className="text-center mb-[4.5rem] md:mb-12">
              <span className="font-serif mb-[5px] -tracking-[.5px] text-xmd block">Vul het formulier in en we komen spoedig bij u terug!</span>
              <h4 className="font-serif font-semibold text-darkgray">Hoe kunnen we u helpen?</h4>
            </Col>
            <Col className="col-xl-10 col-sm-12 col-xs-12">
              <Formik
                initialValues={{ name: '', email: '', phone: '', comment: '', terms_condition: false }}
                validationSchema={ContactFormStyle03Schema}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true)
                  const response = await sendEmail(values)
                  response.status === "success" && resetForm(actions)
                }}
              >
                {({ isSubmitting, status }) => (
                  <Form ref={form}>
                    <Row className="row-cols-1 row-cols-md-2">
                      <Col className="mb-16 sm:mb-[25px]">
                        <Input showErrorMsg={false} type="text" name="name" className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]" labelClass="mb-[25px]" placeholder="Uw naam" />
                        <Input showErrorMsg={false} type="email" name="email" className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]" labelClass="mb-[25px]" placeholder="Uw email adres" />
                        <Input showErrorMsg={false} type="tel" name="phone" className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]" placeholder="Mobiele number" />
                      </Col>
                      <Col className="mb-16 sm:mb-[20px]">
                        <TextArea className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[210px] resize-none" rows="6" name="comment" placeholder="Uw Bericht"></TextArea>
                      </Col>
                      <Col className="text-left sm:mb-[20px]">
                        <Checkbox type="checkbox" name="terms_condition" className="inline-block mt-[4px]" labelClass="flex items-start">
                          <span className="ml-[10px] text-sm inline-block w-[85%]">I accept the terms & conditions and I understand that my data will be hold securely in accordance with the<Link aria-label="checkbox" to="/privacy" target="_blank" className="text-darkgray underline hover:text-fastblue"> privacy policy</Link>.</span>
                        </Checkbox>
                      </Col>
                      <Col className="text-right sm:text-center">
                        <Buttons type="submit" className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${isSubmitting ? " loading" : ""}`} themeColor={["#b884fd", "#fe73a8", "b884fd"]} size="md" color="#fff" title="Stuur bericht" />
                      </Col>
                    </Row>
                    <AnimatePresence>
                      {status && <Row><Col xs={12}><div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}><MessageBox className="mt-[20px] py-[10px]" theme="message-box01" variant="success" message="Your message has been sent successfully!" /></div></Col></Row>}
                    </AnimatePresence>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </m.section>
      {/* Section End */}

        {/* Mail Section Start */}
        {/* <m.section
          id="call-request"
          className="py-[130px] bg-cover bg-no-repeat overflow-hidden relative bg-center lg:py-[90px] md:py-[75px] sm:py-[50px] xs:py-0 xs:border-y"
          style={{
            backgroundImage:
              "url('')",
          }}
          {...fadeIn}
        >
          <Container className="xs:px-0 ">
            <m.div className="row justify-center" {...{ ...fadeIn, transition: { delay: 0.6 } }}>
              <Col xl={7} lg={8} md={10}>
                <div className="px-28 py-20 rounded-md shadow-[0_0_30px_rgba(0,0,0,0.08)] bg-white sm:p-20 xs:rounded-none xs:px-[3.5rem] xs:py-24">
                  <span className="mb-[15px] font-medium text-center	text-[#2d95ef] text-md font-serif uppercase block sm:mb-[10px]">
                    Request a call back
                  </span>
                  <h2 className="heading-5 w-[80%] mb-[40px] font-bold text-center	tracking-[-1px] text-black font-serif uppercase mx-auto xs:w-full">
                    Need a consultant for your business?
                  </h2>
                  <Formik
                    initialValues={{ name: "", email: "" }}
                    validationSchema={ContactFormStyle02Schema}
                    onSubmit={async (values, actions) => {
                      actions.setSubmitting(true)
                      const response = await sendEmail(values)
                      response.status === "success" && resetForm(actions)
                    }}
                  >
                    {({ isSubmitting, status }) => (
                      <Form className="mb-[30px]">
                        <Input showErrorMsg={false} type="text" name="name" labelClass="mb-[25px]" className="rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden" placeholder="Your name" />
                        <Input showErrorMsg={false} type="email" name="email" labelClass="mb-[25px]" className="rounded-[5px] py-[15px] px-[20px] w-full border-[1px] border-solid border-[#dfdfdf] overflow-hidden" placeholder="Your email address" />
                        <Buttons
                          type="submit"
                          className={`btn-fill text-sm leading-none font-medium tracking-[1px] py-[13px] px-[32px] rounded-[4px] w-full uppercase mb-[5px]${isSubmitting ? " loading" : ""}`}
                          themeColor="#2d95ef"
                          color="#fff"
                          size="lg"
                          title="Request a call schedule"
                        />
                        <AnimatePresence>
                          {status && (<m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} > <MessageBox className="mt-[20px] text-center py-[10px]" theme="message-box01" variant="success" message="Your message has been sent successfully!" /> </m.div>)}
                        </AnimatePresence>
                      </Form>
                    )}
                  </Formik>
                  <p className="w-[80%] text-xs leading-6 mx-auto xs:w-full text-center"> We are committed to protecting your privacy. We will never collect information about you without your explicit consent.</p>
                </div>
              </Col>
            </m.div>
          </Container>
        </m.section> */}
        {/* Mail Section End */}

        {/* Footer Start */}
        <FooterStyle04 className="finance-footer" theme="light" logo="/assets/img/webp/nnr.png" />
        {/* Footer End */}
      </InViewPort>
    </div>
  );
};

export default NnrHome;
const HeaderData = [
    {
        title: 'Home',
        link: '/',
        // zoef: "home"
    },
    {
        title: 'Over Ons',
        zoef: "about",
        // dropdown: [
        //     {
        //         title: 'Wagenpark',
        //         link: '/page/wagenpark',
        //     },
        //     {
        //         title: 'Referenties',
        //         link: '/page/referenties',
        //     },
        // ]
    },
    {
        title: 'Diensten',
        zoef: "Diensten",
        link: '/',
        // dropdown: [
        //     {
        //         title: 'Spoedzendingen',
        //         link: '/page/spoedzendingen'
        //     },
        //     {
        //         title: 'Koeriersdiensten',
        //         link: '/page/koeriersdiensten',
        //     },
        //     {
        //         title: 'Beveiligd vervoer',
        //         link: '/page/beveiligd-vervoer',
        //     },
        //     {
        //         title: 'Geconditioneerd vervoer',
        //         link: '/page/geconditioneerd-vervoer',
        //     },
        //     {
        //         title: 'Pallet vervoer',
        //         link: '/page/pallet-vervoer',
        //     },
        //     {
        //         title: 'Project vervoer',
        //         link: '/page/project-vervoer',
        //     },
        //     {
        //         title: 'Maatwerk',
        //         link: '/page/maatwerk',
        //     },
        // ],
    },
    {
        title: 'Contact',
        link: '/page/contact',
    },
]

export default HeaderData